<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Detalle de transferencia</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn dark 
                        color="success" 
                        width="140" 
                        class="float-right"
                        @click="handleDownloadReport">
                        Descargar XLS
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Fecha</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">{{ transfer.register_date | moment("DD/MM/YYYY") }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Origen</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">{{ transfer.departure.name }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Destino</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">{{ transfer.arrival.name }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Estado</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">{{ transfer.status | uppercase }}</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="detail"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                        >

                            <template v-slot:item.category_id="{item}">
                                <span>{{ item.inventory.product.category.name }}</span>
                            </template>

                            <template v-slot:item.description="{item}">
                                <span>{{ item.inventory.product.description }}</span>
                            </template>

                            <template v-slot:item.sent_quantity="{item}">
                                <v-icon v-if="item.imeis || item.imei_from" 
                                    class="mr-3" 
                                    color="#3f51b5" 
                                    size="18"
                                    @click="handleViewImeis(item, 'sent')">
                                    fas fa-search-plus
                                </v-icon>
                                <span class="label-quantity-transfer">{{ item.sent_quantity }}</span>
                            </template>

                            <template v-slot:item.received_quantity="{item}">
                                <v-icon v-if="item.imeis_good" 
                                    class="mr-3" 
                                    color="#43a047" 
                                    size="18"
                                    @click="handleViewImeis(item, 'received')">
                                    fas fa-search-plus
                                </v-icon>
                                <span class="label-quantity-received">{{ item.received_quantity }}</span>
                            </template>

                            <template v-slot:item.lost_quantity="{item}">
                                <v-icon v-if="item.imeis_bad" 
                                    class="mr-3" 
                                    color="#ffc107" 
                                    size="18"
                                    @click="handleViewImeis(item, 'lost')">
                                    fas fa-search-plus
                                </v-icon>
                                <span class="label-quantity-merma">{{ item.lost_quantity }}</span>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <imeis-dialog ref="imeisDialog"></imeis-dialog>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import ImeisDialog from '../../../components/storehouses/ImeisDialog';
import OfficeLogin from '../../../components/auth/OfficeLogin';

export default {
    name: 'DetailTransfers',
    components: {
        ImeisDialog,
        OfficeLogin
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Transferencias',
                    disabled: false,
                    href: '/app/reportes/reporte-transferencias',
                }
            ],
            headers: [
                { text: 'CATEGORIA', value: 'category_id', sortable: false },
                { text: 'PRODUCTO', value: 'description', sortable: false },
                { text: 'CANT. TRANSFERIDA', value: 'sent_quantity', sortable: false, align: 'end' },
                { text: 'CANT. RECIBIDA', value: 'received_quantity', sortable: false, align: 'end' },
                { text: 'CANT. MERMA', value: 'lost_quantity', sortable: false, align: 'end' },
            ]
        }
    },
    computed: {
        ...mapState('report-transfers', [
            'transfer',
            'detail',
            'file_excel'
        ])
    },
    methods: {
        ...mapActions('report-transfers', [
            'getTransferDetail',
            'transferDetailExcel'
        ]),
        async handleDownloadReport () {
            await this.transferDetailExcel(this.transfer);
            if (this.file_excel) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_excel;
                window.open(url);
            }
        },
        handleViewImeis (detail, option) {
            let imei = '';
            if (option == 'sent') {
                if (detail.imeis) {
                    imei = detail.imeis;
                } else if (detail.imei_from) {
                    imei = detail.imei_from + '-' + detail.imei_until;
                }
            } else if (option == 'received') {
                imei = detail.imeis_good;
            } else if (option == 'lost') {
                imei = detail.imeis_bad;
            }
            this.$refs.imeisDialog.show(imei);
        },
    },
    created () {
        this.getTransferDetail(this.transfer);
    }
}
</script>

<style scoped>
.column-amount{
    text-align: right;
}

.label-purchase{
    font-weight: 600;
}

.label-discount{
    color: #F44336;
}

.label-quantity-received{
    background-color: #43a047;
    color: #ffffff;
    display: inline-block;
    width: 50px;
    text-align: center;
    border-radius: 4px;
}

.label-quantity-output{
    background-color: #e53935;
    color: #ffffff;
    display: inline-block;
    width: 50px;
    text-align: center;
    border-radius: 4px;
}

.label-quantity-transfer{
    background-color: #3F51B5;
    color: #ffffff;
    display: inline-block;
    width: 50px;
    text-align: center;
    border-radius: 4px;
}

.label-quantity-merma{
    background-color: #FFC107;
    color: #ffffff;
    display: inline-block;
    width: 50px;
    text-align: center;
    border-radius: 4px;
}
</style>